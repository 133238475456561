<template>
  <BCol lg="3">
    <BCard body-class="border rounded-xl">
      <div class="flex-col justify-between">
        <div class="flex items-center justify-between">
          <div class="flex flex-row items-center gap-3">
            <BImg :src="icon" />
            <div class="text-black font-medium">
              {{ title }}
            </div>
          </div>
          <BImg
            :id="String(target)"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle-dark.svg"
          />
        </div>
        <div class="text-black text-xl font-semibold mt-2">
          {{ total }}
        </div>
      </div>
    </BCard>
    <BPopover
      :target="String(target)"
      triggers="hover"
      placement="top"
    >
      {{ tooltip }}
    </BPopover>
  </BCol>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
  },

}
</script>
